@charset "UTF-8";

@font-face {
  font-family: "icons-font";
  src:url("../fonts/icons-font.eot");
  src:url("../fonts/icons-font.eot?#iefix") format("embedded-opentype"),
    url("../fonts/icons-font.woff") format("woff"),
    url("../fonts/icons-font.ttf") format("truetype"),
    url("../fonts/icons-font.svg#icons-font") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "icons-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icons-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-adn:before {
  content: "a";
}
.icon-aid-medkit-case:before {
  content: "c";
}
.icon-align-center:before {
  content: "f";
}
.icon-align-justify:before {
  content: "g";
}
.icon-align-justify-1:before {
  content: "i";
}
.icon-align-left:before {
  content: "j";
}
.icon-align-right:before {
  content: "k";
}
.icon-ambulance:before {
  content: "l";
}
.icon-anchor:before {
  content: "m";
}
.icon-android:before {
  content: "n";
}
.icon-angle-double-down:before {
  content: "p";
}
.icon-angle-double-left:before {
  content: "q";
}
.icon-angle-double-right:before {
  content: "r";
}
.icon-arrow-circle-up:before {
  content: "s";
}
.icon-arrow-circle-right-line:before {
  content: "t";
}
.icon-arrow-circle-right:before {
  content: "u";
}
.icon-arrow-circle-left:before {
  content: "y";
}
.icon-arrow-circle-down-line:before {
  content: "B";
}
.icon-arrow-circle-down:before {
  content: "C";
}
.icon-archive:before {
  content: "E";
}
.icon-apple:before {
  content: "F";
}
.icon-angle-up:before {
  content: "L";
}
.icon-angle-right:before {
  content: "M";
}
.icon-angle-left:before {
  content: "N";
}
.icon-angle-down:before {
  content: "O";
}
.icon-angle-double-up:before {
  content: "S";
}
.icon-arrow-circle-up-line:before {
  content: "X";
}
.icon-arrow-down:before {
  content: "Z";
}
.icon-arrow-down-1:before {
  content: "7";
}
.icon-arrow-horizontal:before {
  content: "\"";
}
.icon-arrow-left:before {
  content: "$";
}
.icon-arrow-left-1:before {
  content: "'";
}
.icon-arrow-right:before {
  content: "(";
}
.icon-arrow-right-1:before {
  content: ")";
}
.icon-arrow-up:before {
  content: "*";
}
.icon-arrow-up-1:before {
  content: "+";
}
.icon-arrow-vertical:before {
  content: ",";
}
.icon-asterisk:before {
  content: "-";
}
.icon-backward:before {
  content: ".";
}
.icon-briefcase-case:before {
  content: ":";
}
.icon-box-inbox:before {
  content: ";";
}
.icon-bookmark:before {
  content: "<";
}
.icon-book:before {
  content: "=";
}
.icon-bold:before {
  content: ">";
}
.icon-bitcoin:before {
  content: "@";
}
.icon-bitbucket-square:before {
  content: "[";
}
.icon-bitbucket:before {
  content: "]";
}
.icon-bell-two:before {
  content: "^";
}
.icon-bell:before {
  content: "_";
}
.icon-beer:before {
  content: "`";
}
.icon-barcode:before {
  content: "{";
}
.icon-bar-chart:before {
  content: "|";
}
.icon-briefcase-case-two:before {
  content: "}";
}
.icon-bug:before {
  content: "~";
}
.icon-building:before {
  content: "\\";
}
.icon-bullhorn:before {
  content: "\e000";
}
.icon-bullseye:before {
  content: "\e001";
}
.icon-calendar:before {
  content: "\e002";
}
.icon-calendar-1:before {
  content: "\e003";
}
.icon-call-phone:before {
  content: "\e004";
}
.icon-call-phone-square:before {
  content: "\e005";
}
.icon-camera:before {
  content: "\e006";
}
.icon-camera-retro:before {
  content: "\e007";
}
.icon-caret-down:before {
  content: "\e008";
}
.icon-caret-down-two:before {
  content: "\e009";
}
.icon-check-mark-2:before {
  content: "\e00a";
}
.icon-check-mark:before {
  content: "\e00b";
}
.icon-chat-bubble-two:before {
  content: "\e00c";
}
.icon-chat-bubble:before {
  content: "\e00d";
}
.icon-certificate:before {
  content: "\e00e";
}
.icon-caret-up-two:before {
  content: "\e00f";
}
.icon-caret-up-down:before {
  content: "\e010";
}
.icon-caret-up:before {
  content: "\e011";
}
.icon-caret-square-up:before {
  content: "\e012";
}
.icon-caret-square-right:before {
  content: "\e013";
}
.icon-caret-square-down:before {
  content: "\e014";
}
.icon-caret-right:before {
  content: "\e015";
}
.icon-caret-left:before {
  content: "\e016";
}
.icon-check-mark-circle:before {
  content: "\e017";
}
.icon-check-mark-circle-two:before {
  content: "\e018";
}
.icon-check-square:before {
  content: "\e019";
}
.icon-chevron-down:before {
  content: "\e01a";
}
.icon-chevron-down-circle:before {
  content: "\e01b";
}
.icon-chevron-left:before {
  content: "\e01c";
}
.icon-chevron-right:before {
  content: "\e01d";
}
.icon-chevron-up:before {
  content: "\e01e";
}
.icon-chevron-up-circle:before {
  content: "\e01f";
}
.icon-circle:before {
  content: "\e020";
}
.icon-circle-line:before {
  content: "\e021";
}
.icon-clipboard:before {
  content: "\e022";
}
.icon-clock:before {
  content: "\e023";
}
.icon-crosshair-aim:before {
  content: "\e024";
}
.icon-crop:before {
  content: "\e025";
}
.icon-credit-card:before {
  content: "\e026";
}
.icon-contrast:before {
  content: "\e027";
}
.icon-compass:before {
  content: "\e028";
}
.icon-columns:before {
  content: "\e029";
}
.icon-cog-gear:before {
  content: "\e02a";
}
.icon-code-fork:before {
  content: "\e02b";
}
.icon-code:before {
  content: "\e02c";
}
.icon-cloud-upload:before {
  content: "\e02d";
}
.icon-cloud-download:before {
  content: "\e02e";
}
.icon-cloud:before {
  content: "\e02f";
}
.icon-close-delete:before {
  content: "\e030";
}
.icon-css3:before {
  content: "\e031";
}
.icon-cup:before {
  content: "\e032";
}
.icon-cursor-move:before {
  content: "\e033";
}
.icon-cursor-move-two:before {
  content: "\e034";
}
.icon-cut-scissor:before {
  content: "\e035";
}
.icon-cutlery:before {
  content: "\e036";
}
.icon-delete-circle:before {
  content: "\e037";
}
.icon-denied-block:before {
  content: "\e038";
}
.icon-dislike:before {
  content: "\e039";
}
.icon-doctor:before {
  content: "\e03a";
}
.icon-document:before {
  content: "\e03b";
}
.icon-dollar:before {
  content: "\e03c";
}
.icon-download:before {
  content: "\e03d";
}
.icon-exclamation-triangle:before {
  content: "\e03e";
}
.icon-exclamation-circle:before {
  content: "\e03f";
}
.icon-exclamation:before {
  content: "\e040";
}
.icon-exchange:before {
  content: "\e041";
}
.icon-euro:before {
  content: "\e042";
}
.icon-ellipsis-2:before {
  content: "\e043";
}
.icon-email-envelope:before {
  content: "\e044";
}
.icon-ellipsis:before {
  content: "\e045";
}
.icon-eject:before {
  content: "\e046";
}
.icon-edit-write:before {
  content: "\e047";
}
.icon-earser:before {
  content: "\e048";
}
.icon-dropbox:before {
  content: "\e049";
}
.icon-dribble:before {
  content: "\e04a";
}
.icon-external-link:before {
  content: "\e04b";
}
.icon-external-link-sqaure:before {
  content: "\e04c";
}
.icon-eye:before {
  content: "\e04d";
}
.icon-eye-slash-close:before {
  content: "\e04e";
}
.icon-facebook:before {
  content: "\e04f";
}
.icon-facebook-square:before {
  content: "\e050";
}
.icon-fast-backward:before {
  content: "\e051";
}
.icon-fast-forward:before {
  content: "\e052";
}
.icon-female:before {
  content: "\e053";
}
.icon-fighter-jet:before {
  content: "\e054";
}
.icon-file:before {
  content: "\e055";
}
.icon-file-document:before {
  content: "\e056";
}
.icon-file-text:before {
  content: "\e057";
}
.icon-folder3:before {
  content: "\e058";
}
.icon-folder2:before {
  content: "\e059";
}
.icon-folder:before {
  content: "\e05a";
}
.icon-flickr:before {
  content: "\e05b";
}
.icon-flash:before {
  content: "\e05c";
}
.icon-flag-goal:before {
  content: "\e05d";
}
.icon-flag:before {
  content: "\e05e";
}
.icon-flag-1:before {
  content: "\e05f";
}
.icon-fire-extinguisher:before {
  content: "\e060";
}
.icon-fire:before {
  content: "\e061";
}
.icon-filter:before {
  content: "\e062";
}
.icon-filmstrip:before {
  content: "\e063";
}
.icon-files:before {
  content: "\e064";
}
.icon-folder-open:before {
  content: "\e065";
}
.icon-font:before {
  content: "\e066";
}
.icon-fontawesome-webfont:before {
  content: "\e067";
}
.icon-forward:before {
  content: "\e068";
}
.icon-foursquare:before {
  content: "\e069";
}
.icon-gamepad:before {
  content: "\e06a";
}
.icon-gears-setting:before {
  content: "\e06b";
}
.icon-github:before {
  content: "\e06c";
}
.icon-github-square:before {
  content: "\e06d";
}
.icon-gittip:before {
  content: "\e06e";
}
.icon-glass:before {
  content: "\e06f";
}
.icon-globe-world:before {
  content: "\e070";
}
.icon-google-plus:before {
  content: "\e071";
}
.icon-hospital-square-two:before {
  content: "\e072";
}
.icon-hospital-square:before {
  content: "\e073";
}
.icon-hospital:before {
  content: "\e074";
}
.icon-home:before {
  content: "\e075";
}
.icon-heart-empty:before {
  content: "\e076";
}
.icon-heart:before {
  content: "\e077";
}
.icon-headphone:before {
  content: "\e078";
}
.icon-harddrive:before {
  content: "\e079";
}
.icon-hand-up:before {
  content: "\e07a";
}
.icon-hand-right:before {
  content: "\e07b";
}
.icon-hand-down:before {
  content: "\e07c";
}
.icon-group:before {
  content: "\e07d";
}
.icon-google-plus-1:before {
  content: "\e07e";
}
.icon-html5:before {
  content: "\e07f";
}
.icon-indent:before {
  content: "\e080";
}
.icon-information:before {
  content: "\e081";
}
.icon-information-circle:before {
  content: "\e082";
}
.icon-instagram:before {
  content: "\e083";
}
.icon-italic:before {
  content: "\e084";
}
.icon-key:before {
  content: "\e085";
}
.icon-keyboard:before {
  content: "\e086";
}
.icon-laptop:before {
  content: "\e087";
}
.icon-leaf:before {
  content: "\e088";
}
.icon-left:before {
  content: "\e089";
}
.icon-left-circle:before {
  content: "\e08a";
}
.icon-legal:before {
  content: "\e08b";
}
.icon-list:before {
  content: "\e08c";
}
.icon-linux:before {
  content: "\e08d";
}
.icon-linkedin-square:before {
  content: "\e08e";
}
.icon-linkedin:before {
  content: "\e08f";
}
.icon-link-broken:before {
  content: "\e090";
}
.icon-link:before {
  content: "\e091";
}
.icon-like-filled:before {
  content: "\e092";
}
.icon-like:before {
  content: "\e093";
}
.icon-lightbulb:before {
  content: "\e094";
}
.icon-level-up:before {
  content: "\e095";
}
.icon-level-down:before {
  content: "\e096";
}
.icon-letter-mail:before {
  content: "\e097";
}
.icon-lemon:before {
  content: "\e098";
}
.icon-list-2:before {
  content: "\e099";
}
.icon-list-bullet:before {
  content: "\e09a";
}
.icon-list-number:before {
  content: "\e09b";
}
.icon-location-arrow:before {
  content: "\e09c";
}
.icon-lock:before {
  content: "\e09d";
}
.icon-log-in:before {
  content: "\e09e";
}
.icon-log-out:before {
  content: "\e09f";
}
.icon-magic-wand:before {
  content: "\e0a0";
}
.icon-magnet:before {
  content: "\e0a1";
}
.icon-mail-forward:before {
  content: "\e0a2";
}
.icon-male:before {
  content: "\e0a3";
}
.icon-maxcdn:before {
  content: "\e0a4";
}
.icon-mic:before {
  content: "\e0a5";
}
.icon-pencil:before {
  content: "\e0a6";
}
.icon-pause:before {
  content: "\e0a7";
}
.icon-paper-clip:before {
  content: "\e0a8";
}
.icon-outdent:before {
  content: "\e0a9";
}
.icon-music-note:before {
  content: "\e0aa";
}
.icon-moon-sleep:before {
  content: "\e0ab";
}
.icon-money-banknote:before {
  content: "\e0ac";
}
.icon-minus-square:before {
  content: "\e0ad";
}
.icon-minus-square-1:before {
  content: "\e0ae";
}
.icon-minus-line:before {
  content: "\e0af";
}
.icon-minus-circle:before {
  content: "\e0b0";
}
.icon-minus:before {
  content: "\e0b1";
}
.icon-mic-no:before {
  content: "\e0b2";
}
.icon-pencil-square:before {
  content: "\e0b3";
}
.icon-phone-mobile:before {
  content: "\e0b4";
}
.icon-photo:before {
  content: "\e0b5";
}
.icon-pin-map:before {
  content: "\e0b6";
}
.icon-pin-map-2:before {
  content: "\e0b7";
}
.icon-pinterest:before {
  content: "\e0b8";
}
.icon-pinterest-square:before {
  content: "\e0b9";
}
.icon-plane-airport:before {
  content: "\e0ba";
}
.icon-play:before {
  content: "\e0bb";
}
.icon-play-circle:before {
  content: "\e0bc";
}
.icon-plus:before {
  content: "\e0bd";
}
.icon-plus-circle:before {
  content: "\e0be";
}
.icon-plus-square:before {
  content: "\e0bf";
}
.icon-pound:before {
  content: "\e0c0";
}
.icon-power-off:before {
  content: "\e0c1";
}
.icon-present-gift:before {
  content: "\e0c2";
}
.icon-prize-award:before {
  content: "\e0c3";
}
.icon-ptint:before {
  content: "\e0c4";
}
.icon-puzzle:before {
  content: "\e0c5";
}
.icon-qrcode:before {
  content: "\e0c6";
}
.icon-question:before {
  content: "\e0c7";
}
.icon-question-1:before {
  content: "\e0c8";
}
.icon-quote-left:before {
  content: "\e0c9";
}
.icon-quote-right:before {
  content: "\e0ca";
}
.icon-random:before {
  content: "\e0cb";
}
.icon-refresh:before {
  content: "\e0cc";
}
.icon-remove:before {
  content: "\e0cd";
}
.icon-renren:before {
  content: "\e0ce";
}
.icon-repeat-redo:before {
  content: "\e0cf";
}
.icon-reply-all:before {
  content: "\e0d0";
}
.icon-reply-mail:before {
  content: "\e0d1";
}
.icon-resize-down:before {
  content: "\e0d2";
}
.icon-resize-expand:before {
  content: "\e0d3";
}
.icon-retweet:before {
  content: "\e0d4";
}
.icon-right:before {
  content: "\e0d5";
}
.icon-road:before {
  content: "\e0d6";
}
.icon-rocket:before {
  content: "\e0d7";
}
.icon-rss:before {
  content: "\e0d8";
}
.icon-rss-two:before {
  content: "\e0d9";
}
.icon-rupee:before {
  content: "\e0da";
}
.icon-save-disk:before {
  content: "\e0db";
}
.icon-science-laboratory:before {
  content: "\e0dc";
}
.icon-screen-desktop:before {
  content: "\e0dd";
}
.icon-search-find:before {
  content: "\e0de";
}
.icon-share-square:before {
  content: "\e0df";
}
.icon-share-square-1:before {
  content: "\e0e0";
}
.icon-shield:before {
  content: "\e0e1";
}
.icon-shopping-cart:before {
  content: "\e0e2";
}
.icon-signal:before {
  content: "\e0e3";
}
.icon-sitemap:before {
  content: "\e0e4";
}
.icon-skype:before {
  content: "\e0e5";
}
.icon-smile:before {
  content: "\e0e6";
}
.icon-smiley-frown:before {
  content: "\e0e7";
}
.icon-smiley-meh:before {
  content: "\e0e8";
}
.icon-sort-alpha:before {
  content: "\e0e9";
}
.icon-sort-alpha-two:before {
  content: "\e0ea";
}
.icon-sort-amount:before {
  content: "\e0eb";
}
.icon-sort-amount-two:before {
  content: "\e0ec";
}
.icon-sort-numeric:before {
  content: "\e0ed";
}
.icon-sort-numeric-two:before {
  content: "\e0ee";
}
.icon-spinner:before {
  content: "\e0ef";
}
.icon-square:before {
  content: "\e0f0";
}
.icon-square-line:before {
  content: "\e0f1";
}
.icon-stackoverflow:before {
  content: "\e0f2";
}
.icon-star:before {
  content: "\e0f3";
}
.icon-star-half:before {
  content: "\e0f4";
}
.icon-star-half-1:before {
  content: "\e0f5";
}
.icon-star-two:before {
  content: "\e0f6";
}
.icon-step-backward:before {
  content: "\e0f7";
}
.icon-step-forward:before {
  content: "\e0f8";
}
.icon-stethoscope:before {
  content: "\e0f9";
}
.icon-stop:before {
  content: "\e0fa";
}
.icon-strikethrough:before {
  content: "\e0fb";
}
.icon-subscript:before {
  content: "\e0fc";
}
.icon-sun:before {
  content: "\e0fd";
}
.icon-superscript:before {
  content: "\e0fe";
}
.icon-table:before {
  content: "\e0ff";
}
.icon-tablet:before {
  content: "\e100";
}
.icon-tachometer:before {
  content: "\e101";
}
.icon-tag:before {
  content: "\e102";
}
.icon-tag-2:before {
  content: "\e103";
}
.icon-tags:before {
  content: "\e104";
}
.icon-talk-chat:before {
  content: "\e105";
}
.icon-talk-chat-2:before {
  content: "\e106";
}
.icon-tasks:before {
  content: "\e107";
}
.icon-terminal:before {
  content: "\e108";
}
.icon-text-height:before {
  content: "\e109";
}
.icon-text-width:before {
  content: "\e10a";
}
.icon-thumbnails:before {
  content: "\e10b";
}
.icon-thumbnails-large:before {
  content: "\e10c";
}
.icon-ticket:before {
  content: "\e10d";
}
.icon-tint:before {
  content: "\e10e";
}
.icon-trash-bin:before {
  content: "\e10f";
}
.icon-trello:before {
  content: "\e110";
}
.icon-truck:before {
  content: "\e111";
}
.icon-tumblr:before {
  content: "\e112";
}
.icon-tumblr-square:before {
  content: "\e113";
}
.icon-twitter:before {
  content: "\e114";
}
.icon-twitter-square:before {
  content: "\e115";
}
.icon-umbrella:before {
  content: "\e116";
}
.icon-underline:before {
  content: "\e117";
}
.icon-undo:before {
  content: "\e118";
}
.icon-unlike:before {
  content: "\e119";
}
.icon-unlock:before {
  content: "\e11a";
}
.icon-unlock-2:before {
  content: "\e11b";
}
.icon-upload:before {
  content: "\e11c";
}
.icon-user:before {
  content: "\e11d";
}
.icon-video-camera:before {
  content: "\e11e";
}
.icon-vk:before {
  content: "\e11f";
}
.icon-volume-down:before {
  content: "\e120";
}
.icon-volume-down-1:before {
  content: "\e121";
}
.icon-volume-up:before {
  content: "\e122";
}
.icon-weibo:before {
  content: "\e123";
}
.icon-window-list:before {
  content: "\e124";
}
.icon-windows:before {
  content: "\e125";
}
.icon-won:before {
  content: "\e126";
}
.icon-wrench:before {
  content: "\e127";
}
.icon-xing:before {
  content: "\e128";
}
.icon-xing-square:before {
  content: "\e129";
}
.icon-yen:before {
  content: "\e12a";
}
.icon-youtube:before {
  content: "\e12b";
}
.icon-youtube-play:before {
  content: "\e12c";
}
.icon-youtube-square:before {
  content: "\e12d";
}
.icon-yuan:before {
  content: "\e12e";
}
.icon-zoom-in:before {
  content: "\e12f";
}
.icon-zoom-out:before {
  content: "\e130";
}
.icon-brush-paint-streamline:before {
  content: "b";
}
.icon-bubble-love-streamline-talk:before {
  content: "d";
}
.icon-caddie-shopping-streamline:before {
  content: "e";
}
.icon-chaplin-hat-movie-streamline:before {
  content: "h";
}
.icon-chef-food-restaurant-streamline:before {
  content: "o";
}
.icon-cocktail-mojito-streamline:before {
  content: "v";
}
.icon-coffee-streamline:before {
  content: "w";
}
.icon-computer-imac:before {
  content: "x";
}
.icon-computer-macintosh-vintage:before {
  content: "z";
}
.icon-computer-streamline:before {
  content: "A";
}
.icon-cook-pan-pot-streamline:before {
  content: "D";
}
.icon-crown-king-streamline:before {
  content: "G";
}
.icon-danger-death-delete-destroy-skull-stream:before {
  content: "H";
}
.icon-dashboard-speed-streamline:before {
  content: "I";
}
.icon-database-streamline:before {
  content: "J";
}
.icon-delete-garbage-streamline:before {
  content: "K";
}
.icon-drug-medecine-streamline-syringue:before {
  content: "P";
}
.icon-earth-globe-streamline:before {
  content: "Q";
}
.icon-eat-food-fork-knife-streamline:before {
  content: "R";
}
.icon-eat-food-hotdog-streamline:before {
  content: "T";
}
.icon-envellope-mail-streamline:before {
  content: "U";
}
.icon-eye-dropper-streamline:before {
  content: "V";
}
.icon-factory-lift-streamline-warehouse:before {
  content: "W";
}
.icon-first-aid-medecine-shield-streamline:before {
  content: "Y";
}
.icon-food-ice-cream-streamline:before {
  content: "0";
}
.icon-grid-lines-streamline:before {
  content: "1";
}
.icon-handle-streamline-vector:before {
  content: "2";
}
.icon-happy-smiley-streamline:before {
  content: "3";
}
.icon-headset-sound-streamline:before {
  content: "4";
}
.icon-home-house-streamline:before {
  content: "5";
}
.icon-iphone-streamline:before {
  content: "6";
}
.icon-japan-streamline-tea:before {
  content: "8";
}
.icon-laptop-macbook-streamline:before {
  content: "9";
}
.icon-lock-locker-streamline:before {
  content: "!";
}
.icon-magic-magic-wand-streamline:before {
  content: "#";
}
.icon-magnet-streamline:before {
  content: "%";
}
.icon-man-people-streamline-user:before {
  content: "&";
}
.icon-map-streamline-user:before {
  content: "/";
}
.icon-micro-record-streamline:before {
  content: "?";
}
.icon-monocle-mustache-streamline:before {
  content: "\e131";
}
.icon-music-note-streamline:before {
  content: "\e132";
}
.icon-music-speaker-streamline:before {
  content: "\e133";
}
.icon-paint-bucket-streamline:before {
  content: "\e134";
}
.icon-painting-roll-streamline:before {
  content: "\e135";
}
.icon-pen-streamline-1:before {
  content: "\e136";
}
.icon-pen-streamline-3:before {
  content: "\e137";
}
.icon-receipt-shopping-streamline:before {
  content: "\e138";
}
.icon-remote-control-streamline:before {
  content: "\e139";
}
.icon-settings-streamline:before {
  content: "\e13a";
}
.icon-shoes-snickers-streamline:before {
  content: "\e13b";
}
.icon-stamp-streamline:before {
  content: "\e13c";
}
.icon-streamline-suitcase-travel:before {
  content: "\e13d";
}
.icon-streamline-sync:before {
  content: "\e13e";
}
.icon-armchair-chair-streamline:before {
  content: "\e13f";
}
.icon-arrow-streamline-target:before {
  content: "\e140";
}
.icon-backpack-streamline-trekking:before {
  content: "\e141";
}
.icon-bag-shopping-streamline:before {
  content: "\e142";
}
.icon-barbecue-eat-food-streamline:before {
  content: "\e143";
}
.icon-barista-coffee-espresso-streamline:before {
  content: "\e144";
}
.icon-bomb-bug:before {
  content: "\e145";
}
.icon-book-dowload-streamline:before {
  content: "\e146";
}
.icon-book-read-streamline:before {
  content: "\e147";
}
.icon-browser-streamline-window:before {
  content: "\e148";
}
.icon-bubble-comment-streamline-talk:before {
  content: "\e149";
}
.icon-computer-imac-2:before {
  content: "\e14a";
}
.icon-clock-streamline-time:before {
  content: "\e14b";
}
.icon-camera-streamline-video:before {
  content: "\e14c";
}
.icon-camera-photo-streamline:before {
  content: "\e14d";
}
.icon-camera-photo-polaroid-streamline:before {
  content: "\e14e";
}
.icon-caddie-shop-shopping-streamline:before {
  content: "\e14f";
}
.icon-computer-network-streamline:before {
  content: "\e150";
}
.icon-crop-streamline:before {
  content: "\e151";
}
.icon-design-graphic-tablet-streamline-tablet:before {
  content: "\e152";
}
.icon-design-pencil-rule-streamline:before {
  content: "\e153";
}
.icon-diving-leisure-sea-sport-streamline:before {
  content: "\e154";
}
.icon-edit-modify-streamline:before {
  content: "\e155";
}
.icon-email-mail-streamline:before {
  content: "\e156";
}
.icon-frame-picture-streamline:before {
  content: "\e157";
}
.icon-like-love-streamline:before {
  content: "\e158";
}
.icon-ipod-streamline:before {
  content: "\e159";
}
.icon-ipod-music-streamline:before {
  content: "\e15a";
}
.icon-ipod-mini-music-streamline:before {
  content: "\e15b";
}
.icon-ipad-streamline:before {
  content: "\e15c";
}
.icon-ink-pen-streamline:before {
  content: "\e15d";
}
.icon-ibook-laptop:before {
  content: "\e15e";
}
.icon-link-streamline:before {
  content: "\e15f";
}
.icon-locker-streamline-unlock:before {
  content: "\e160";
}
.icon-macintosh:before {
  content: "\e161";
}
.icon-map-pin-streamline:before {
  content: "\e162";
}
.icon-photo-pictures-streamline:before {
  content: "\e163";
}
.icon-picture-streamline:before {
  content: "\e164";
}
.icon-picture-streamline-1:before {
  content: "\e165";
}
.icon-pen-streamline-2:before {
  content: "\e166";
}
.icon-pen-streamline:before {
  content: "\e167";
}
.icon-painting-pallet-streamline:before {
  content: "\e168";
}
.icon-notebook-streamline:before {
  content: "\e169";
}
.icon-settings-streamline-1:before {
  content: "\e16a";
}
.icon-settings-streamline-2:before {
  content: "\e16b";
}
.icon-speech-streamline-talk-user:before {
  content: "\e16c";
}
.icon-streamline-umbrella-weather:before {
  content: "\e16d";
}
